import React from 'react';
import '../styles/website.css';
import Target from "../assets/icons/target.png";
import Sun from "../assets/icons/sun.png";


const Webseiten = () => {
  return (
    <div className='WebsitePage'>
      <section className='websiteHeadingSection'>
        <div className='WebsiteHeading'>
          <h1 className='websiteHeading'>
            Webseiten mit NodiaTech
          </h1>
        </div>
      </section>
      <section className='websiteInfoSection'>
        <div className='websiteGrid'>
          <div className='websiteInfoDiv'>
          </div>
          <div className='websiteDarkCard'>
            <img src={Target} width={45} height={45} alt="" />
            <p>
              Die Webseite eines Unternehmens ist dessen Online-Gesicht und es ist wichtig, dass sie genau das ausdrückt, was das Unternehmen und dessen Marke ausmacht.
              <br />
              <br />
              Egal ob man nach einem modernen, minimalistischen Design sucht oder eine auffällige Präsentation der Marke bevorzugt, wir sind hier, um zu helfen.
            </p>
          </div>
          <div className='websiteDarkCard'>
            <img src={Sun} width={45} height={45} alt="" />
            <p>
              Gemeinsam kann man eine Webseite erstellen, die sich von der Masse abhebt und das Unternehmen im besten Licht darstellt. 
              <br />
              <br />Talentierte Designer und Entwickler arbeiten eng mit den Kunden zusammen, um deren Visionen und Ziele zu verstehen und umzusetzen.
              <br />
              <br />
              Durch aktive Kommunikation und enge Zusammenarbeit kann sichergestellt werden, dass das endgültige Ergebnis genau dem entspricht, was der Kunde sich vorgestellt hat.
            </p>
          </div>
          <div className='websiteLightCard'>
            <h1 className='lightHeading'>
              Die Visionen unserer Kunden werden in enger Zusammenarbeit besprochen und anschließend deren Umsetzung ausgearbeitet.
            </h1>
            <div className='cardGrid'>
              <p className='cardGridItemHeading1'>
                Konzeptionierung
              </p>
              <p className='cardGridItem1'>
              In dieser Phase steht das Verständnis der individuellen Bedürfnisse und Ziele des Kunden im Vordergrund. Wir nehmen uns die Zeit, Ihre Wünsche für das Webseiten-Design detailliert aufzunehmen und passende Vorschläge für Funktionen, Layout und Stil zu erarbeiten. Gemeinsam legen wir den Grundstein für eine benutzerfreundliche und ansprechende Webseite, die Ihre Zielgruppe begeistert.              </p>
              <p className='cardGridItemHeading2'>
              Prototyperstellung
              </p>
              <p className='cardGridItem2'>
              Basierend auf den besprochenen Ideen und Anforderungen entwickeln wir erste Prototypen für die Webseite. Diese dienen als visuelle Darstellung des späteren Webdesigns und geben einen Eindruck von Struktur, Navigation und Benutzererfahrung. In enger Abstimmung mit dem Kunden werden Anpassungen und Optimierungen vorgenommen, um sicherzustellen, dass der Prototyp den Vorstellungen entspricht.              </p>
              <p className='cardGridItemHeading3'>
              Entwicklung und Veröffentlichung
              </p>
              <p className='cardGridItem3'>
              Sobald der Prototyp genehmigt wurde, beginnt die eigentliche Entwicklung der Webseite. Hierbei wird das zuvor erstellte Design in eine funktionsfähige Webseite umgesetzt, indem alle notwendigen Programmierarbeiten durchgeführt und Inhalte integriert werden. Nach der Fertigstellung und einer umfassenden Qualitätskontrolle ist die Webseite bereit, das Licht der Welt zu erblicken. Dabei unterstützen wir Sie auch bei der Auswahl eines passenden Webhostings und der Einrichtung der Domain, um einen reibungslosen Start Ihrer Webseite zu gewährleisten.              </p>
            </div>
          </div>
            <div className='websiteColoredCard'>
              <p>
                Die Web-Entwickler von NodiaTech verfügen über umfangreiche Erfahrung in der Entwicklung von Web-Applikationen.
              <br />
              <br />
                Die tiefe Technologie-Kenntnis und jahrelange Praxis ermöglichen die Erstellung von performanten, skalierbaren und benutzerfreundlichen Anwendungen, die genau auf die Anforderungen des Kunden abgestimmt sind.
              <br />
              <br />
                Egal ob es sich um eine einfache Web-App oder eine komplexe Enterprise-Lösung handelt.
              </p>
            </div>
            <div className='MobileBanner'>
              <h1>
                Natürlich auf allen Geräten.
              </h1>
              <p>
                Sämtliche Webseiten werden für Mobilgeräte optimiert, um diese von überall aus genießen zu können.
              </p>
            </div>
        </div>
      </section>
      <div className='imageHolder'>
      </div>
    </div>
  );
};

export default Webseiten;
