import React from 'react';
import '../styles/auftreten.css'
import Icon from "../assets/icons/print.png";
import IconTwo from "../assets/icons/cam.png";
import LottiePlayer from "react-lottie-player";
import AppearanceLottie from "../assets/lottie/absract 3 (1).json";

const Auftreten = () => {
  return (
    <div className='AppearancePage'>
      <div className="AppearanceHeadingHolder">
                <h1 className="AppearanceHeading">
                  Design ist keine Frage des Geschmacks, sondern eine Frage der Perspektive und mit dem richtigen Blickwinkel wird es zur Kunst!
                </h1>
      </div>
      <section className='AppearanceSection'>
        <div className='appearanceGrid'>
        <div className='appearanceLightCard'>
            <h1 className='appGridHeading'>
              Die Visionen unserer Kunden werden in enger Zusammenarbeit besprochen und anschließend deren Umsetzung ausgearbeitet.
            </h1>
            <div className='appearancecardGrid'>
              <p className='appearancecardGridItemHeading1'>
                Konzeptionierung 
              </p>
              <p className='appearancecardGridItem1'>
              Wir hören genau zu, was unsere Kunden möchten und benötigen, um ihre Visionen in die Realität umzusetzen. In dieser Phase erarbeiten wir gemeinsam mit dem Kunden kreative Ideen und Lösungen, die auf ihre individuellen Bedürfnisse und Anforderungen zugeschnitten sind. Wir halten es für wichtig, eine solide Basis für den weiteren Verlauf des Projekts zu schaffen.              </p>

              <p className='appearancecardGridItemHeading2'>
              Prototyperstellung 
              </p>
              <p className='appearancecardGridItem2'>
              Hier entwickeln wir erste Entwürfe und Skizzen, die den Ideen aus der Konzeptionsphase entsprechen. Anschließend präsentieren wir diese dem Kunden und diskutieren mögliche Anpassungen und Verbesserungen. Wir sind erst zufrieden, wenn der Kunde vollkommen überzeugt ist und den Entwurf als passende Umsetzung seiner Vision ansieht.              </p>
              <p className='appearancecardGridItemHeading3'>
              Fertigungsphase 
              </p>
              <p className='appearancecardGridItem3'>
              In dieser Phase setzen wir die genehmigten Entwürfe und Konzepte in die Tat um. Bei der Erstellung von Logos, Flyern und Bannern werden die Entwürfe in druckfertige Dateien umgewandelt, die für die Produktion von Marketingmaterialien verwendet werden können. Bei Imagefilmen und Drohnenaufnahmen wird das zugrunde liegende Konzept in einen fertigen Film umgesetzt, indem die Aufnahmen vor Ort durchgeführt, die Drohnensequenzen eingefangen und schließlich alles in der Postproduktion zusammengefügt und bearbeitet wird. </p>            </div>
          </div>
          <div className='appearanceDoubleCard'>
          <div className='appearanceDarkCard'>
            <img src={ IconTwo } width={35} height={35} alt="" />
            <p>
            Wir möchten unseren Kunden eine breite Palette an kreativen Lösungen anbieten, daher ergänzen wir unsere Dienstleistungen um Imagefilme. <br />
              <br />
              Unser Ziel ist es, Ihrem Unternehmen zu ermöglichen, seine Botschaft auf authentische und ansprechende Weise zu vermitteln.
              <br />
              <br />
              Dabei legen wir Wert darauf, Ihre individuellen Wünsche und die Persönlichkeit Ihres Unternehmens im Film widerzuspiegeln.            </p>
          </div>
          <div className='appearanceDarkCard'>
            <img src={ Icon } width={35} height={35} alt="" />
            <p>
            Wir bei NodiaTech unterstützen unsere Kunden auch bei der Auswahl der richtigen Druckerei und Produktionspartner, um ein optimales Ergebnis zu erzielen.               <br />
              <br />
              Dies erleichtert den gesamten Prozess und sorgt dafür, dass die fertigen Materialien den hohen Ansprüchen unserer Kunden gerecht werden.              <br />
            </p>
          </div>
          </div>
        </div>
        <div className="container_row">
          <div className="layer1">
            <div className='appearanceLightCardLottie'>
              <h3>
                Mit einem starken und ansprechenden <span className='appearanceSpan'>Design</span> ist der Weg zu einem erfolgreichen Auftritt bereitet.
              </h3>
            </div>
            <div className='appearanceColoredCard'>
              <h3 className='appearancePara'>
              Ein besonderer Aspekt unserer Imagefilme sind Drohnenaufnahmen. Mit moderner Technik und einem verantwortungsbewussten Umgang mit Drohnen möchten wir Ihrem Imagefilm eine zusätzliche Perspektive bieten.               <br />
              <br /> 
              Mit unseren Imagefilmen und Drohenaufnahmen möchten wir dazu beitragen, Ihr Unternehmen und seine Werte auf besondere Art und Weise zu präsentieren.               <br />
              <br />
              Dabei steht für uns immer im Vordergrund, Ihre Botschaft auf ehrliche und ansprechende Weise zu kommunizieren, um eine langfristige und positive Wirkung zu erzielen.              <br />
              </h3>
            </div>
          </div>
          <div className="layer2">
            <LottiePlayer
            className='AppearanceLottie'
                animationData={AppearanceLottie}
                loop={false}
                style={{ width: "100%", height: "100%" }}
              />
          </div>
      </div>
      </section>
    </div>
  );
};

export default Auftreten;