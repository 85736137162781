import React from 'react';
import { Link } from 'react-router-dom';
import NodiaTechLogo from "../assets/images/Frame 4.png"
import '../styles/header.css';

const Header = () => {

  var MenuInput = document.getElementById("newPageLoaded");

  function LinkClicked () {
    MenuInput?.click();
    window.location.reload();
  }

  return (
    <header>
      <Link to="/" className="nodia-tech-link">
        <img className='nodiatech-logo' src={ NodiaTechLogo } width={200} height={50} alt="" />
      </Link>
      <nav>

        <input type="checkbox" id='newPageLoaded' className='toggle-menu'/>
        <div className='hamburger-line'></div>

        <div className='mobileMenu'>
          <ul className="mobileMenuList">
          <li className='mobile' onClick={LinkClicked}>
              <Link className='nav-link-mobile' to="/">Startseite</Link>
            </li>
            <li className='mobile' onClick={LinkClicked}>
              <Link className='nav-link-mobile' to="/webseiten">Webseiten</Link>
            </li>
            <li className='mobile' onClick={LinkClicked}>
              <Link className='nav-link-mobile' to="/anwendungen">Anwendungen</Link>
            </li>
            <li className='mobile' onClick={LinkClicked}>
              <Link className='nav-link-mobile' to="/auftreten">Auftreten</Link>
            </li>
            <li className='mobile' onClick={LinkClicked}>
              <Link className='nav-link-mobile' to="/unternehmen">Über Uns</Link>
            </li>
            <li className='mobile' onClick={LinkClicked}>
              <Link to="/kontakt">
                <button className='contact-nav-button'>
                  Kontakt
                </button>
              </Link>
            </li>
          </ul>
        </div>

        <ul className="computerMenu">
          <li>
            <Link className='nav-link' to="/">Startseite</Link>
          </li>
          <li>
            <Link className='nav-link' to="/webseiten">Webseiten</Link>
          </li>
          <li>
            <Link className='nav-link' to="/anwendungen">Anwendungen</Link>
          </li>
          <li>
            <Link className='nav-link' to="/auftreten">Auftreten</Link>
          </li>
          <li>
            <Link className='nav-link' to="/unternehmen">Über Uns</Link>
          </li>
          <li>
            <Link to="/kontakt">
              <button className='contact-nav-button'>
                Kontakt
              </button>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;