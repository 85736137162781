import React from "react";
import "../styles/sitemap.css";


const Sitemap = () => {
    return(
        <div className="sitemap-page">
            <h1 className="sitemap-heading">Sitemap</h1>
            <div className="sitemap-list-grid">
                <ul className="sitemap-list">
                    <p>Angebot</p>
                    <li placeholder="Angebot">
                        <a href="/webseiten" className="sitemap-link">Webseiten mit NodiaTech</a>                    
                    </li>
                    <li>
                        <a href="/auftreten" className="sitemap-link">Auftreten</a>
                    </li>
                    <li>
                        <a href="/anwendungen" className="sitemap-link">Anwendungen mit NodiaTech</a>
                    </li>
                </ul>
                <ul className="sitemap-list">
                    <p>Unternehmen</p>
                    <li placeholder="Angebot">
                        <a href="/kontakt" className="sitemap-link">NodiaTech Kontakt</a>                    
                    </li>
                    <li>
                        <a href="/unternehmen" className="sitemap-link">Unternehmen</a>
                    </li>
                </ul>
                <ul className="sitemap-list">
                    <p>Rechtliches</p>
                    <li placeholder="Angebot">
                        <a href="/impressum" className="sitemap-link">Impressum</a>                    
                    </li>
                    <li>
                        <a href="/datenschutz" className="sitemap-link">Datenschutz</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sitemap;