import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import App from './App';

const originalConsoleError = console.error;

console.error = function (...args) {
  if (
    typeof args[0] === "string" &&
    args[0].includes("WebSocket connection") &&
    args[0].includes("failed")
  ) {
    return;
  }
  originalConsoleError.apply(console, args);
};

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
