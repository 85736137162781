import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Webseiten from './components/Webseiten';
import Auftreten from './components/Auftreten';
import Header from './components/Header';
import Footer from './components/Footer';
import Kontakt from './components/Kontakt';
import Unternehmen from './components/Unternehmen';
import Anwendungen from './components/Anwendungen';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import Sitemap from "./components/Sitemap";
import ScrollToTop from './ScrollToTop';
import Cookies from './components/Cookies';




function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/webseiten" element={<Webseiten />} />
        <Route path="/auftreten" element={<Auftreten />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/unternehmen" element={<Unternehmen />} />
        <Route path="/anwendungen" element={<Anwendungen />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/sitemap" element={<Sitemap />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
