import React from "react";
import "../styles/about.css";
import PersonalCard from "../assets/images/pexels-polina-tankilevitch-5234774.jpg"

const Unternehmen = () => {
    return(
        <div className="AboutPage">
            <div className="aboutInfo">
                <h1>Das Unternehmen NodiaTech</h1>
                <p>
                Unser Motto lautet „Noli Dicere Actum“, was bedeutet, nicht nur über etwas zu reden, sondern es auch durchzuführen. Bei uns sprechen Taten lauter als Worte. 
                Denn nur indem man handelt, zeigt man die Aufrichtigkeit seiner Worte. 
                <br/>
                <br/>
                Bei NodiaTech versprechen wir, die beste Qualität zu fairen Preisen zu liefern. Wir versuchen stets, einen hochwertigen und zuverlässigen Service zu einem günstigen Preis zu bieten, da wir der Meinung sind, dass die durchschnittlichen Preise der IT-Branche oft unrealistisch und maßstabslos in die Höhe getrieben wurden.
                <br/>
                <br/>
                Wir bieten eine breite Palette an Produkten der IT-Industrie an. Von leistungsstarken Software-Lösungen und Apps über Datenbanken, Netzwerke und Webdesign bis hin zu umfangreichen Digital- und Online-Marketing-Strategien arbeiten wir mit unseren Kunden zusammen, um deren technologische Bedürfnisse zu erfüllen.
                <br/>
                <br/>
                Wir können versichern, dass es unser Ziel ist, unseren Kunden das Beste zu bieten, und wir nutzen alle unsere Erfahrungen und Fähigkeiten, um unsere Kunden zufriedenzustellen und ihnen den bestmöglichen Service zuliefern. Unser professionelles Team von Entwicklern, Designern und Beratern kann Ihnen helfen, Ihr IT-Projekt erfolgreich zu managen, egal ob es sich um ein neues Unternehmensprojekt, ein bestehendes IT-Projekt oder eine neue Applikation handelt.
                <br/>
                <br/>
                Wir bei NodiaTech arbeiten stets hart daran, unseren Kunden eine einzigartige und maßgeschneiderte Erfahrung mit unseren Produkten zu bieten. Wir sind fest entschlossen, fortschrittliche Technologien und moderne IT-Lösungen zu nutzen und neue Wege zusammen mit unseren Kunden zu gehen.
                </p>
            </div>

            {/* <section className="aboutSection">
                <div className="aboutGrid">
                    <div className="aboutPersonalCard">
                        <img src={ PersonalCard } alt="" />
                        <h1 className="quote">
                            Jan Ehlig <br />
                            <span>Geschaftsführerender Gesellschafter</span> 
                        </h1>
                    </div>
                    <div className="aboutPersonalCard">
                        <img src={ PersonalCard } alt="" />
                        <h1 className="quote">
                            Christian Bucher <br />
                            <span>Gesellschafter</span>
                            <br />
                            <br />
                            <p></p>
                        </h1>
                    </div>
                </div>
            </section> */}
        </div>
    );
};

export default Unternehmen;