import React from "react";
import '../styles/anwendungen.css';
import PlanungIcon from "../assets/icons/handshake-3310.png";
import kundenanforderungen from "../assets/icons/kundenanforderungen.png";
import entwicklung from "../assets/icons/entwicklung.png";
import testen from "../assets/icons/testen.png";
import veroeffentlichen from "../assets/icons/veroeffentlichung.png";
import wartung from "../assets/icons/wartung.png";
import planung from "../assets/icons/planung.png";

const Anwendungen = () => {
    return(
        <div className="AppPage">
            <div className="appHeadingHolder">
                <h1 className="AppHeading">Anwendungen mit NodiaTech</h1>
            </div>
            <section className="AppInfoSection">
                <div className="AppGrid">
                    <div className="coloredcardHolder">
                        <div className="coloredAppCard">
                            <h1 className="appInfoText">
                                Da jedes Projekt einzigartig ist, schaffen wir Kundennutzen und Effizienz, 
                                indem wir einen projektspezifischen Ansatz verfolgen, der auf erfolgsorientierter Umsetzung und Reaktion basiert. 
                            </h1>
                        </div>
                        <div className="coloredAppCard">
                            <h1 className="appInfoText">
                                NodiaTech zielt darauf ab, Anwendungen zu entwickeln, 
                                die schnell und effizient sind und die es Ihnen ermöglichen, unternehmerische Leistungen und Aktivitäten reibungslos auszuführen. 
                            </h1>
                        </div>
                    </div>
                    <div className="expandableLightCard">
                        <div className="expandablecardHeadingHolder">
                            <h1>Fertigungsprozess</h1>
                            <p>
                                Als Kunde können Sie erwarten, dass NodiaTech maßgeschneiderte Software entwickelt, 
                                die genau auf Ihre Anforderungen zugeschnitten ist und somit alle Ihre Bedürfnisse erfüllt. 
                            </p>
                        </div>
                        <div className="expandableGrid">
                            <div className="expandableParagraph">
                                <div className="app-span-holder">
                                    <span className="alwaysOnSpan">
                                        <code className="app-code-heading">Kundenanforderungen</code>
                                    </span>
                                    <img className="app-heading-icon" src={ kundenanforderungen } width={35} height={35} alt="" />
                                </div>
                                <br />
                                <span className="expandedSpan" id="expandSpan">
                                    Zuerst sammeln wir alle wichtigen Anforderungen des Kunden, um zu verstehen, was in welchem Umfang benötigt wird.
                                </span>
                            </div>
                            <div className="expandableParagraph">
                                <div className="app-span-holder">
                                    <span className="alwaysOnSpan">
                                        <code className="app-code-heading">Planung</code>
                                    </span>
                                    <img className="app-heading-icon" src={ planung } width={35} height={35} alt="" />
                                </div>
                                <br />
                                <span className="expandedSpan" id="expandSpan">
                                Anschließend werden wir einen Entwicklungsplan erstellen, der alle Phasen der Anwendungsentwicklung dokumentiert. 
                                    Beim Erstellen dieses Plans werden Schätzungen der benötigten Ressourcen, der Komplexität des Projekts und des Entwicklungszeitraums aufgestellt.
                                </span>
                            </div>
                            <div className="expandableParagraph">
                                <div className="app-span-holder">
                                    <span className="alwaysOnSpan">
                                        <code className="app-code-heading">Entwicklung</code>
                                    </span>
                                    <img className="app-heading-icon" src={ entwicklung } width={35} height={35} alt="" />
                                </div>
                                <br />
                                <span className="expandedSpan" id="expandSpan">
                                In dieser Phase beginnt die eigentliche Entwicklung der Anwendung. Der Entwicklungsprozess besteht aus vier Phasen: 
                                    Der Planung der Benutzeroberfläche, der Erstellung des Anwendungscodes, Tests und Debugging und schließlich der Integration der bestehenden Daten.
                                </span>
                            </div>
                            <div className="expandableParagraph">
                                <div className="app-span-holder">
                                    <span className="alwaysOnSpan">
                                        <code className="app-code-heading">Testen</code>
                                    </span>
                                    <img className="app-heading-icon" src={ testen } width={35} height={35} alt="" />
                                </div>
                                <br />
                                <span className="expandedSpan" id="expandSpan">
                                Bevor neue Anwendungen veröffentlicht werden, müssen sie einem komplexen Testdurchlauf unterzogen werden, um sicherzustellen, dass die Anwendung fehlerfrei funktioniert.
                                </span>
                            </div>
                            <div className="expandableParagraph">
                                <div className="app-span-holder">
                                    <span className="alwaysOnSpan">
                                        <code className="app-code-heading">Veröffentlichung</code>
                                    </span>
                                    <img className="app-heading-icon" src={ veroeffentlichen } width={35} height={35} alt="" />
                                </div>
                                <br />
                                <span className="expandedSpan" id="expandSpan">
                                Nach erfolgreichem Testen wird die Anwendung dem Kunden bereitgestellt.
                                </span>
                            </div>
                            <div className="expandableParagraph">
                            <div className="app-span-holder">
                                    <span className="alwaysOnSpan">
                                        <code className="app-code-heading">Wartung</code>
                                    </span>
                                    <img className="app-heading-icon" src={ wartung } width={35} height={35} alt="" />
                                </div>
                                <br />
                                <span className="expandedSpan" id="expandSpan">
                                Es werden regelmäßige Wartungsarbeiten durchgeführt, um sicherzustellen, dass die Anwendungen reibungslos laufen. 
                                    Dies kann einmal pro Quartal erfolgen oder öfter, je nach Anforderungen des Kunden.
                                </span>
                            </div>
                        </div>
                        <div className="Iconholder">
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Anwendungen;