import React, { useRef, useState } from "react";
import "../styles/contact.css";
import phone from "../assets/icons/phone-call-icon.png";
import mail from "../assets/icons/mail-5714.png";
import loc from "../assets/icons/location-pointer-2961.png";


const Kontakt = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formAttempted, setFormAttempted] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const [agreement, setAgreement] = useState(false);

  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setAgreement(event.target.checked);
  }

  const getInputValidationClass = (name: keyof typeof formValues, attempted: boolean) => {
    if (!attempted) {
      return "";
    }
  
    if (formValues[name] === "") {
      return "invalid";
    }
  
    return "";
  };

const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormAttempted(true);

    if (
      formValues.name === "" ||
      formValues.email === "" ||
      formValues.message === ""
    ) {
      return;
    }

    const formData = new FormData(event.currentTarget);
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const phone = formData.get("phone") as string;
    const message = formData.get("message") as string;
  
    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
      }),
    });
  
    if (response.ok) {
      alert("E-Mail erfolgreich gesendet.");
    } else {
      const errorData = await response.json();
      if (errorData.message === "Die E-Mail-Domain existiert nicht.") {
        alert("Die E-Mail-Domain existiert nicht.");
      } else if (errorData.message === "Ungültige E-Mail-Adresse.") {
        alert("Ungültige E-Mail-Adresse.");
      } else {
        alert("Fehler beim Senden der E-Mail.");
      }
    }
  };

  return (
    <div className="contactPage">
      <h1>Unser Kontakt</h1>
      <p>Im Nachfolgenden finden Sie unsere Kontaktdaten sowie das Kontaktformular für direkte Anfragen.</p>
      <section className="ContactSection">
        <div className="infoGrid">
          <div className="infoCard">
            <img className="contact-icon"  src={phone} width={32} height={32} alt="" />
            <a className="contactInfo" href="tel:+4936614554746">+49 3661 / 4554746</a>
          </div>
          <div className="infoCard">
            <img className="contact-icon"  src={mail} width={32} height={32} alt="" />
            <a className="contactInfo" href="mailto:info@nodiatech.de">info@nodiatech.de</a>
          </div>
          <div className="infoCard">
            <img className="contact-icon" src={loc} width={32} height={32} alt="" />
              <a className="contactInfo" href="https://maps.apple.com/?address=Schönfelder%20Str.%20101,%2007973%20Greiz" target="_blank">
                Schönfelder Str. 101, 07973 Greiz
              </a>
          </div>
        </div>
      </section>
      <section className="ContactForm">
        <h1 className="Formheading">Sie haben ein Anliegen?</h1>
        <form onSubmit={handleSubmit}>
          <div className="inputGroup">
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              placeholder="Name"
              required
              className={getInputValidationClass("name", formAttempted)}
            />
          </div>
          <div className="inputGroup">
            <input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              placeholder="E-Mail"
              required
              className={getInputValidationClass("email", formAttempted)}
            />
          </div>
          <div className="inputGroup">
            <input
              type="tel"
              name="phone"
              value={formValues.phone}
              onChange={handleInputChange}
              placeholder="Telefon (optional)"
            />
          </div>
          <div className="inputGroup">
            <textarea
              name="message"
              value={formValues.message}
              onChange={handleInputChange}
              placeholder="Ihr Anliegen"
              required
              className={getInputValidationClass("message", formAttempted)}
            />
          </div>
          <div className="agb-consent-holder">
            <input type="checkbox" name="agreement" onChange={handleChange} className="agb-consent-checkbox"/>
            <p>Ich habe die Datenschutzerklärung zur Kenntnis genommen. 
              Ich stimme zu, dass die von mir übermittelten Daten zur Kontaktaufnahme und für Rückfragen dauerhaft gespeichert werden. Diese Zustimmung kann ich jederzeit persönlich widerrufen.
            </p>
          </div>
          <button disabled={!agreement} type="submit">Nachricht senden</button>
        </form>
      </section>
    </div>
  );
};

export default Kontakt;
function getCheckboxvalue(event: Event | undefined) {
  throw new Error("Function not implemented.");
}

function setAgreement(checked: any) {
  throw new Error("Function not implemented.");
}

