import { Link } from 'react-router-dom';
import '../styles/footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className='footer-content-holder'>
        <p>Bei Fragen und Anliegen wenden Sie sich an uns über das <span><Link to="/kontakt" className='footerBlueLink'>Kontaktformular.</Link></span></p>
        <div className='horiz-divider-footer'></div>
        <p>Öffnungszeiten: <br /></p>
        <p>Montag-Freitag von 9:00 bis 18:00 Uhr</p>
        
          <ul>
              <li>
                <Link to="/impressum">Impressum</Link>
              </li>
              <li>
                <Link to="/datenschutz">Datenschutz</Link>
              </li>
              <li className='footer-last-link'>
                <Link to="/sitemap" className='footer-last-link-a'>Sitemap</Link>
              </li>
            </ul>
          <p className="footer-text">
            &copy; {currentYear} NodiaTech. Alle Rechte vorbehalten.
          </p>
      </div>
      </footer>
  );
};

export default Footer;