import React, { useEffect, useState, useRef } from "react";
import '../styles/landingPage.css';
import LottiePlayer from "react-lottie-player";
import animationData from "../assets/lottie/35546-laptop.json";
import serviceIcon from "../assets/icons/cog.png";
import hourGlass from "../assets/icons/reach.png";
import videoSource from "../assets/videos/PacificLanding.mp4";
import playButton from "../assets/icons/PlayButton.png";
import pauseButton from "../assets/icons/PauseButton.png";

const LandingPage: React.FC = () => {
  const [message, setMessage] = useState("");
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoPaused, setVideoPaused] = useState(true);

  const handleVideoToggle = () => {
    if (videoRef.current?.paused) {
      videoRef.current?.play();
      setVideoPaused(false);
    } else {
      videoRef.current?.pause();
      setVideoPaused(true);
    }
  };
  
  return (
    <div className="Intro-holder">
      <section className="LandingPageSection">
        <div className="grid-container introSection">
          <div className="gridItem">
            <div className="introTextContainer">
              <h1 className="introHeading">Beginne mit NodiaTech</h1>
              <p className="introParagraph">Weil wir nicht reden, sondern handeln.</p>
            </div>
            <LottiePlayer
              className="LandingLottie"
              animationData={animationData}
              loop={false}
              play
            />
          </div>
          <div className="gridItem websiteSection">
            <div className="LandingPageHeadingContainer">
            <h1 className="LandingPageHeadingbig">
              Weil jede Webseite etwas Besonderes ist.‍
            </h1>
            <h1 className="LandingPageHeading">
              Wir erschaffen Webseiten, welche an die Bedürfnisse unserer Kunden angepasst sind. Durch individuelles und responsives Design wird jede Webseite das, was sie sein sollte. Etwas Besonderes.</h1>
              <div className="link-container">
                <a className="text-link" href="/webseiten">
                  <p className="link">
                    Mehr erfahren
                  </p>
                  <svg color="#3a68ff" width="25" height="25" viewBox="0 -1 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.13 8.5L6.87296 4.24291L5.87067 5.24379L9.129 8.5L5.87067 11.7555L6.87225 12.7571L11.13 8.5Z" fill="currentColor"/>
              </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="gridItem appSection">
          <div className="LandingPageHeadingContainer">
            <h1 className="LandingPageHeadingbig">
              Weil gute Ideen umgesetzt werden sollten.
            </h1>
            <h1 className="LandingPageHeading">Wir erwecken Ideen zum Leben und entwickeln sie nicht nur für ein Betriebssystem. Sondern für alle gewünschten.</h1>
            <a className="text-link" href="/anwendungen">
              <p className="link">
                Mehr erfahren
              </p>
              <svg color="#3a68ff" width="25" height="25" viewBox="0 -1 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.13 8.5L6.87296 4.24291L5.87067 5.24379L9.129 8.5L5.87067 11.7555L6.87225 12.7571L11.13 8.5Z" fill="currentColor"/>
              </svg>
              </a>
            </div>
          </div>
          <div className="gridItem appearanceSection">
            <video
              ref={videoRef}
              className="appearanceSectionVideo"
              src={videoSource}
              muted
              loop
              autoPlay
              playsInline
              onPlay={() => setVideoPaused(false)}
              onPause={() => setVideoPaused(true)}
            ></video>
            <div className="playPauseholder">
              <button className="playpauseButton" onClick={handleVideoToggle}>
                {videoPaused ? <img src={ playButton } width={32} height={32} alt="" /> : <img src={ pauseButton } alt="" width={32} height={32} />}
              </button>
            </div>
            <div className="LandingPageHeadingContainerVideo appearanceSectionText">
                <h1 className="LandingPageHeadingbig">
                Weil der erste Eindruck zählt.
                </h1>
                <h1 className="LandingPageHeading">Um das Beste eines Unternehmens richtig in Szene zu setzen, sorgen wir für ein erstklassiges Auftreten.</h1>
                <a className="text-link" href="/auftreten">
                    <p className="link">
                      Mehr erfahren
                    </p>
                    <svg color="#3a68ff" width="25" height="25" viewBox="0 -1 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.13 8.5L6.87296 4.24291L5.87067 5.24379L9.129 8.5L5.87067 11.7555L6.87225 12.7571L11.13 8.5Z" fill="currentColor"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>

      <section className="infoSection">
        <div className="infoSectionHolder">
          <div className="serviceCard">
            <img width={35} height={35} src={ serviceIcon } alt="" />
            <h1 className="LandingServiceHeading">Service</h1>
            <p className="LandingService">
              Damit die digitale Infrastruktur stets erreichbar bleibt und für einen störungsfreien Arbeitsalltag gesorgt werden kann, 
              gehört Wartung, Anpassung und Installation neuer beziehungsweise vorhandener Infrastruktur zu unserem Repertoire. 
            </p>
          </div>
          <div className="reachableCard">
            <img width={35} height={35} src={ hourGlass } alt="" />
            <h1 className="LandingReachableHeading">Erreichbarkeit</h1>
            <p className="LandingReachable">
            Immer der perfekte Ansprechpartner, um keine Zeit zu verlieren. Bei Störungen, Änderungswünschen oder sonstigen Anliegen 
            steht dem Kunden von NodiaTech eine ausgewiesene Anlaufstelle zur Verfügung. Diese wird von Mitarbeitern besetzt, die stets mit den Begebenheiten des Kunden vertraut sind.
            </p>
          </div>
        </div>
      </section>

      <section className="infoSection">
      <div className="LandingPageHeadingLight">
          <h1>Rede nicht, handle!</h1>
          <p>Wir lassen Taten, statt Worte sprechen und streben danach, dies in unserer täglichen Arbeit widerzuspiegeln. 
            Aus dieser Philosophie heraus, sind wir stets bemüht, Lösungen anzubieten, die nicht nur den Bedürfnissen unserer Kunden entsprechen, sondern diese sogar übertreffen.
          </p>
          <a className="text-link" href="/kontakt">
              <p className="link">
                Mehr erfahren
              </p>
              <svg color="#3a68ff" width="25" height="25" viewBox="0 -1 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.13 8.5L6.87296 4.24291L5.87067 5.24379L9.129 8.5L5.87067 11.7555L6.87225 12.7571L11.13 8.5Z" fill="currentColor"/>
              </svg>
              </a>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
